/*
 * @Descripttion: 饼状图
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-01 15:37:02
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-07 16:29:41
 */
import * as echarts from 'echarts'

// 门店认证
function storeAuthEcharts(el,data) {
    var option = {
        title: {
            text: '门店认证',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '门店认证',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
                // formatter:  '{b} : {c} ({d}%)',
                // formatter:  '{c} ({d}%)',
                // position: 'inner',
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 支付认证
function applyAuthEcharts(el,data) {
    var option = {
        title: {
            text: '支付认证',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '支付认证',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
                // formatter:  '{b} : {c} ({d}%)',
                // formatter:  '{c} ({d}%)',
                // position: 'inner',
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 反馈
function feedbackEcharts(el,data) {
    var option = {
        title: {
            text: '客户反馈',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '客户反馈',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 活跃云店
function activeShopEcharts(el,data) {
    var option = {
        title: {
            text: '门店状态',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '门店状态',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 有效客户
function efficientPieEcharts(el,data) {
    var option = {
        title: {
            text: '全部客户',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '全部客户',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 跟进阶段
function poolPieEcharts(el,data) {
    var option = {
        title: {
            text: '跟进阶段',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '跟进阶段',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 回访数据
function determinePieEcharts(el,data) {
    var option = {
        title: {
            text: '回访数据',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '回访数据',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 品牌是否合作数据
function cooperationMtPieEcharts(el,data) {
    var option = {
        title: {
            text: '合作品牌',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '合作品牌',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 全景类型
function panoramicTypePieEcharts(el,data) {
    var option = {
        title: {
            text: '全景类型',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '全景类型',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 功能类型
function functionPieEcharts(el,data) {
    var option = {
        title: {
            text: '功能类型',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '功能类型',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 风格类型
function stylePieEcharts(el,data) {
    var option = {
        title: {
            text: '风格类型',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '风格类型',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 营收数据分析
function revenuePieEcharts(el,data) {
    var option = {
        title: {
            text: '品牌营收',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c}'+'元'+' ({d}%)'
        },
        // legend: {
        //     bottom: '0%',
        // },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '品牌营收',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

  
export {
    storeAuthEcharts,
    applyAuthEcharts,
    feedbackEcharts,
    activeShopEcharts,
    efficientPieEcharts,
    poolPieEcharts,
    determinePieEcharts,
    cooperationMtPieEcharts,
    panoramicTypePieEcharts,
    functionPieEcharts,
    stylePieEcharts,
    revenuePieEcharts
}